<template>
  <v-expand-transition>
    <div v-if="condition" class="error-message">
      {{ message }}
    </div>
  </v-expand-transition>
</template>

<script>
export default {
  props: {
    condition: {
      type: Boolean,
      default: false,
    },
    message: {
      type: String,
      default: "",
    },
  },
};
</script>

<style scoped>
.error-message {
  font-size: 15px;
  color: var(--v-error-base);
}
</style>